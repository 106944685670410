import React, { useState, useEffect } from "react";
import { GeneralStyledContent } from "../../../../../../styled/common-styled";
import LangContext from "modules/shared/context/langContext";
import { connect } from "react-redux";
import {
  Steps,
  Row,
  Col,
  Form,
  Select,
  Divider,
  Input,
  Tabs,
  Spin,
} from "antd";
import * as yup from "yup";
import { Formik } from "formik";
import {
  loadPolicyType,
  loadVehicleGroup,
  loadVehicle,
  loadVehicleByVehicleGroup,
  createPolicy,
  loadPolicyById,
  updatePolicy,
} from "../../../fetchAPI/apiClient";
import LineToken from "../SettingNotification/LineToken";
import {
  initialValuesPolicy,
  checkPayload,
} from "../../../constants/template-policy";
import Breadcrumb from "../../../../../shared/components/Breadcrumb";
import SelectedForm from "../Template/SelectedForm";
import {
  checkInitialValues,
  resetFormHelper,
} from "../Helper/Helper";
import TableTransfer from "../../../../../shared/components/TableTransfer";
import PolicyHasUser from "../SettingNotification/PolicyHasUser";

const { Item } = Form;
const FormItem = Item;
const Option = Select.Option;
const { Step } = Steps;
const { TabPane } = Tabs;
let dataFromEdit = null;

const From = (props) => {
  const [current, setCurrent] = useState(0);
  const [policyType, setPolicyType] = useState([]);
  const [subPolicyType, setSubPolicyType] = useState([]);
  const [vehicleGroup, setVehicleGroup] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [dataForm, setDataForm] = useState(null);
  const [loading, setLoading] = useState(false);
  const [schema, setSchema] = useState(
    yup.object().shape({
      policyType: yup.string().required("กรุณาเลือกประเภทข้อมูล"),
      dataType: yup.string().required("กรุณาเลือกประเภทข้อมูล"),
      name: yup.string().required("กรุณากรอกชื่อ"),
    })
  );
  const [isDataType, setIsDataType] = useState("D");

  useEffect(() => {
    if (props.type == "edit") {
      setLoading(true);
      let url = window.location.pathname;
      let id = url.substring(url.lastIndexOf("/") + 1);
      loadPolicyById(id, (statusCode, response) => {
        dataFromEdit = response.data.data;
        setIsDataType(response.data.data.policy_type);
        loadData();
        setLoading(false);
      });
    } else {
      loadData();
    }
  }, []);

  useEffect(() => {
    if (isDataType === "A_VCD") {
      setSchema(
        yup.object().shape({
          policyType: yup.string().required("กรุณาเลือกประเภทข้อมูล"),
          dataType: yup.string().required("กรุณาเลือกประเภทข้อมูล"),
          name: yup.string().required("กรุณากรอกชื่อ"),
          ringToneYellowVCD: yup.array().of(
            yup.object().shape({
              time: yup
                .string()
                .required("กรุณาเลือกเวลา")
                .nullable(),
              audio: yup
                .string()
                .required("กรุณาเลือกเสียง")
                .nullable(),
            })
          ),
          ringToneRed: yup.string().required("กรุณาเลือกเสียง"),
        })
      );
    } else {
      yup.object().shape({
        policyType: yup.string().required("กรุณาเลือกประเภทข้อมูล"),
        dataType: yup.string().required("กรุณาเลือกประเภทข้อมูล"),
        name: yup.string().required("กรุณากรอกชื่อ"),
      });
    }
  }, [isDataType]);

  useEffect(() => {
    if (props.type == "edit") {
      let indexPolicyType = policyType.findIndex(
        (item) => item["id"] == dataFromEdit["type"]
      );
      if (indexPolicyType != -1) {
        setSubPolicyType(policyType[indexPolicyType].children);
      }
    } else {
      if (policyType.length != 0) {
        setSubPolicyType(policyType[0].children);
      }
    }
  }, [policyType]);

  const loadData = () => {
    loadPolicyType(
      props.type == "edit" ? "?withTrash=true" : "",
      {},
      (statusCode, response) => {
        setPolicyType(response.data.data);
      }
    );
    loadVehicleGroup(
      { company_id: props.profile.company_id },
      (statusCode, response) => {
        setVehicleGroup(response.data.data);
      }
    );
    loadVehicle(
      { company_id: props.profile.company_id },
      (statusCode, response) => {
        for (let i = 0; i < response.data.data.length; i++) {
          response.data.data[i].key = response.data.data[i]["id"];
          response.data.data[i].duplicate = false;
          delete response.data.data[i].id;
        }
        setVehicleList(response.data.data);
      }
    );
  };

  const next = () => {
    setCurrent((prev) => prev + 1);
  };

  const prev = () => {
    setCurrent((prev) => prev - 1);
  };

  const axiosCreateAndUpdateNewPolicy = (dataForm, LineToken, users) => {
    setLoading(true);
    if (props.type == "edit") {
      let payload = checkPayload(dataForm, LineToken, props.profile.company_id, users)
      updatePolicy(
        dataForm["id"],
        payload,
        (statusCode, response) => {
          if (response.data.status === 200) {
            window.location.replace("/policy-new");
          }
        }
      );
    } else {
      createPolicy(
        checkPayload(dataForm, LineToken, props.profile.company_id, users),
        (statusCode, response) => {
          if (response.data.status === 200) {
            window.location.replace("/policy-new");
          }
        }
      );
    }
  };

  function filterVehicleIdsInLineToken(data, filterVehicles) {
    data.line_token.forEach((token) => {
      token.vehicles = token.vehicles.filter(
        (vehicleId) => !filterVehicles.includes(vehicleId)
      );
    });
    return data;
  }

  const steps = [
    {
      title: (
        <label style={{ padding: 0, margin: 0, fontSize: 16 }}>
          กำหนดนโยบาย
        </label>
      ),
      description: (
        <label
          style={{ padding: 0, margin: 0, color: "#BCBCBC", fontSize: 14 }}
        >
          ตั้งค่านโยบายสำหรับการแจ้งเตือน
        </label>
      ),
      content: (
        <Formik
          initialValues={checkInitialValues(
            dataForm,
            props.type,
            dataFromEdit,
            initialValuesPolicy
          )}
          enableReinitialize={true}
          validationSchema={schema}
          onSubmit={(values) => {
            if (values.max_temp < values.min_temp) {
              window.alert("อุณหภูมิต่ำสุดห้ามมากกว่าอุณหภูมิสูงสุด");
            } else {
              setDataForm(values);
              setTargetKeys(values.vehicleSelected);
              next();
            }
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleFocus,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Divider style={{ margin: "10px 0px" }} />
                </Col>
                {/* {JSON.stringify(values)} */}
                <Col span={12}>
                  <FormItem>
                    <label
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                      }}
                    >
                      นโยบาย
                    </label>

                    <Divider style={{ margin: "10px 0px", width: "100%" }} />
                  </FormItem>
                  <FormItem
                    label={
                      <LangContext.Consumer>
                        {(i18n) => i18n.n.name}
                      </LangContext.Consumer>
                    }
                    required={true}
                    validateStatus={touched.name && errors.name && "error"}
                    help={touched.name && errors.name}
                  >
                    <Input
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setFieldValue("name", e.target.value);
                      }}
                      value={values.name}
                    />
                  </FormItem>
                  <FormItem
                    label={
                      <LangContext.Consumer>
                        {(i18n) => i18n.p.policyType}
                      </LangContext.Consumer>
                    }
                    required={true}
                    validateStatus={
                      touched.policyType && errors.policyType && "error"
                    }
                    help={touched.policyType && errors.policyType}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="กรุณาเลือก"
                      optionFilterProp="children"
                      onChange={(e) => {
                        let getSubPolicy = policyType.find(
                          (item) => item.id == e
                        );
                        setFieldValue("policyType", e);
                        setFieldValue(
                          "dataType",
                          getSubPolicy["children"][0]["id"]
                        );
                        setSubPolicyType(getSubPolicy.children);
                        setIsDataType(getSubPolicy["children"][0]["id"]);
                        resetFormHelper(setFieldValue);
                      }}
                      name="policyType"
                      value={values.policyType}
                      disabled={props.type == "edit" ? true : false}
                    >
                      {policyType.map((item) => (
                        <Option key={item.id.toString()} value={item.id}>
                          <LangContext.Consumer>
                            {(i18n) => i18n.policy[item.name]}
                          </LangContext.Consumer>
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                  <SelectedForm
                    setFieldValue={setFieldValue}
                    values={values}
                    touched={touched}
                    errors={errors}
                    subPolicyType={subPolicyType}
                    current={current}
                    steps={steps}
                    prev={prev}
                    companyId={props.profile.company_id}
                    type={props.type}
                    policyType={values["policyType"]}
                    setIsDataType={setIsDataType}
                  />
                </Col>

                <Col span={12}>
                  <FormItem>
                    <label
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                      }}
                    >
                      พาหนะ
                    </label>
                    <Divider style={{ margin: "10px 0px" }} />
                  </FormItem>
                  <FormItem
                    label={
                      <LangContext.Consumer>
                        {(i18n) => i18n.v.vehicleGroup}
                      </LangContext.Consumer>
                    }
                    validateStatus={
                      touched.vehicleGroup && errors.vehicleGroup && "error"
                    }
                    help={touched.vehicleGroup && errors.vehicleGroup}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="กรุณาเลือก"
                      optionFilterProp="children"
                      value={values.vehicleGroup}
                      onChange={(e) => {
                        let vehicleArr = [];
                        loadVehicleByVehicleGroup(
                          {
                            company_id: props.profile.company_id,
                            vehicle_visibility:
                              props.profile.vehicle_visibility,
                            vehicle_groups_id: e,
                          },
                          (statusCode, response) => {
                            if (response.data.data.length > 0) {
                              response.data.data.map((item) => {
                                vehicleArr.push(item.id);
                              });
                            }
                            setFieldValue("vehicleGroup", e);
                            setFieldValue("vehicleSelected", vehicleArr);
                            setTargetKeys(vehicleArr);
                          }
                        );
                      }}
                    >
                      <Option value={"all"}>
                        <LangContext.Consumer>
                          {(i18n) => i18n.a.all}
                        </LangContext.Consumer>
                      </Option>
                      {vehicleGroup.map((item) => (
                        <Option key={item.id.toString()} value={item.id}>{item.name}</Option>
                      ))}
                    </Select>
                  </FormItem>
                  <FormItem
                    label={
                      <LangContext.Consumer>
                        {(i18n) => i18n.v.vehicle}
                      </LangContext.Consumer>
                    }
                    validateStatus={
                      touched.vehicleSelected &&
                      errors.vehicleSelected &&
                      "error"
                    }
                    help={touched.vehicleSelected && errors.vehicleSelected}
                  >
                    {/* <Transfer
                      dataSource={vehicleList}
                      titles={["เลือก", "เลือก"]}
                      targetKeys={values.vehicleSelected}
                      // selectedKeys={selectedKeys}
                      onChange={(nextTargetKeys, direction, moveKeys) => {
                        if (props.type == "edit") {
                          filterVehicleIdsInLineToken(dataFromEdit, moveKeys);
                        }
                        handleChangeTransfer(
                          nextTargetKeys,
                          direction,
                          moveKeys,
                          setFieldValue
                        );
                      }}
                      // onSelectChange={handleSelectChange}
                      filterOption={(inputValue, item) =>
                        item.plate_no.indexOf(inputValue) !== -1
                      }
                      render={(item) => item.plate_no}
                      listStyle={{
                        width: "46%",
                        height: 400,
                      }}
                      showSearch
                    /> */}

                    <TableTransfer
                      dataSource={vehicleList}
                      targetKeys={values.vehicleSelected}
                      showSearch
                      onChange={(nextTargetKeys, direction, moveKeys) => {
                        if (props.type == "edit") {
                          filterVehicleIdsInLineToken(dataFromEdit, moveKeys);
                        }
                        handleChangeTransfer(
                          nextTargetKeys,
                          direction,
                          moveKeys,
                          setFieldValue
                        );
                      }}
                      filterOption={(inputValue, item) =>
                        item.plate_no.indexOf(inputValue) !== -1
                      }
                      leftColumns={[
                        {
                          dataIndex: "plate_no",
                          title: (
                            <div style={{ fontSize: 15 }}>
                              <LangContext.Consumer>
                                {(i18n) => i18n.i.itemLeft}
                              </LangContext.Consumer>
                            </div>
                          ),
                          posiction: "Left",
                        },
                      ]}
                      rightColumns={[
                        {
                          dataIndex: "plate_no",
                          title: (
                            <div style={{ fontSize: 15 }}>
                              <LangContext.Consumer>
                                {(i18n) => i18n.i.itemSelected}
                              </LangContext.Consumer>
                            </div>
                          ),
                          posiction: "Right",
                        },
                      ]}
                    />
                  </FormItem>
                </Col>
              </Row>
            </Form>
          )}
        />
      ),
    },
    {
      title: (
        <label style={{ padding: 0, margin: 0, fontSize: 16 }}>
          ตั้งค่าการแจ้งเตือน
        </label>
      ),
      description: (
        <label
          style={{ padding: 0, margin: 0, color: "#BCBCBC", fontSize: 14 }}
        >
          กำหนดการแจ้งเตือนไปยังผู้ใช้
        </label>
      ),
      content: (
        <>
          <Tabs defaultActiveKey="1" onChange={(key) => { }}>
            <TabPane tab="Line" key="1">
              <LineToken
                current={current}
                steps={steps}
                prev={prev}
                vehicleList={vehicleList}
                targetKeys={targetKeys}
                createPolicy={(lineToken) => {
                  let users = !!dataFromEdit ? dataFromEdit["users"] : []
                  axiosCreateAndUpdateNewPolicy(dataForm, lineToken, users);
                }}
                dataForm={dataForm}
                dataFromEdit={!!dataFromEdit ? dataFromEdit["line_token"] : []}
                type={props.type}
                vehicleSelected={
                  !!dataFromEdit
                    ? dataFromEdit["line_token"].reduce(
                      (acc, obj) => acc.concat(obj.vehicles),
                      []
                    )
                    : []
                }
                profile={props.profile}
                vehicleGroup={vehicleGroup}
              />
            </TabPane>
            <TabPane tab="User" key="2">
              <PolicyHasUser
                current={current}
                prev={prev}
                createPolicy={(users) => {
                  let token = !!dataFromEdit ? { setting: dataFromEdit["line_token"] } : { setting: [] }
                  axiosCreateAndUpdateNewPolicy(dataForm, token, users);
                }}
                type={props.type}
                userSelected={!!dataFromEdit ? dataFromEdit["users"] : []}
                profile={props.profile}
              />
            </TabPane>
            <TabPane tab="Email" key="3" disabled>
              Content of Tab Pane 2
            </TabPane>
            <TabPane tab="Website" key="4" disabled>
              Content of Tab Pane 3
            </TabPane>
            <TabPane tab="Incident Dashboard" key="5" disabled>
              Content of Tab Pane 3
            </TabPane>
          </Tabs>
        </>
      ),
    },
    {
      title: (
        <label style={{ padding: 0, margin: 0, fontSize: 16 }}>
          ยืนยันข้อมูล
        </label>
      ),
      description: (
        <label
          style={{ padding: 0, margin: 0, color: "#BCBCBC", fontSize: 14 }}
        >
          ตรวจสอบความถูกต้องของข้อมูล
        </label>
      ),
      content: "Last-content",
    },
  ];

  const handleChangeTransfer = (
    nextTargetKeys,
    direction,
    moveKeys,
    setFieldValue
  ) => {
    setTargetKeys(nextTargetKeys);
    setFieldValue("vehicleSelected", nextTargetKeys);
  };

  return (
    <GeneralStyledContent>
      <Spin spinning={loading}>
        <Row type="flex" justify="center">
          <Col span={22}>
            <Breadcrumb
              match={props.match}
              style={{ margin: "0px 0px 14px 10px" }}
            />
          </Col>
          <Col span={22}>
            <Steps current={current}>
              {steps.map((item, i) => (
                <Step
                  key={i.toString()}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </Steps>
            <div>{steps[current].content}</div>
          </Col>
        </Row>
      </Spin>
    </GeneralStyledContent>
  );
};

export default connect(
  ({ auth, auth: { profile } }) => ({
    auth,
    profile,
  }),
  {}
)(From);
